import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { PaperEditorial, PaperHeroSmall } from '@/ui/Typography/Typography'

import { BannerData } from '@/services/contentful/plp/plp'
import DisplayLink from '@/ui/Link/DisplayLink'

function linkTarget(href: string) {
  return href.includes(String(process.env.APP_DOMAIN)) ? '_self' : '_blank'
}

export const renderCarouselHeroText = (document: BannerData['text'], key: number) => {
  const options: Options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (children ? <PaperHeroSmall>{children}</PaperHeroSmall> : null),
      [BLOCKS.PARAGRAPH]: (node, children) => (children ? <PaperEditorial>{children}</PaperEditorial> : null),
      [INLINES.HYPERLINK]: ({ data }, children) =>
        children && data?.uri ? (
          <DisplayLink
            id={`banner-slide-text-link-${key}`}
            color="secondary"
            href={data.uri}
            target={linkTarget(data.uri)}
          >
            {children}
          </DisplayLink>
        ) : null,
    },
    renderText: (text) => {
      if (!text) return
      return text
    },
  }

  return documentToReactComponents(document?.json, options)
}
