import { TrackingProps } from '@/components/Tracking/TrackingProvider/TrackingProvider'
import { useTracking } from '@/components/Tracking/useTracking/useTracking'
import useOnScreen from '@/domains/product/legacy/Catalog/hooks'
import { PromotionViewed } from '@/generated/analytics'
import type { Banner } from '@/generated/contentful/contentful'
import useCheckMobileScreen from '@/modules/helpers/mobileScreenHook'
import { Button } from '@/ui/Button'
import { Image, mediaAPILoader } from '@/ui/Image/Image'
import { NavigationBullets } from '@/ui/NavigationBullets/NavigationBullets'
import { trackPromotionClicked, trackPromotionViewed, trackingString } from '@/utils/analytics/helpers'
import { isTenant } from '@/utils/config'
import { compose } from '@xo-union/react-css-modules'
import { CarouselWithStaticVisibleSlots as UnionCarousel } from '@xo-union/ui-carousel'
import cx from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import { renderCarouselHeroText } from './CarouselHero.adapter'
import { NextButton, PrevButton } from './CarouselHeroButtons/CarouselHeroButtons'
import stylesTK from './CarouselHeroTK.module.scss'
import stylesWW from './CarouselHeroWW.module.scss'

function redirectTo(link: string) {
  window.location.assign(link)
}

const styles = isTenant('ww') ? stylesWW : stylesTK

const HALF_CAROUSEL_HEIGHT = '-250px'

type HomeBanner = Pick<
  Banner,
  | 'text'
  | 'buttonText'
  | 'buttonLink'
  | 'frontImageDesktop'
  | 'frontImageMobile'
  | 'page'
  | 'location'
  | 'promotion'
  | 'bannerLink'
  | 'template'
  | 'justifyText'
>
type CarouselHeroProps = {
  homeBanners: HomeBanner[]
}

export function CarouselHero({ homeBanners }: CarouselHeroProps) {
  const [navigationIndex, setNavigationIndex] = useState(0)
  const [navigationRange, setNavigationRange] = useState<Range | undefined>()
  const isMobile = useCheckMobileScreen()
  const carouselRef = useRef<HTMLDivElement | null>(null)

  const isVisible = useOnScreen(carouselRef, HALF_CAROUSEL_HEIGHT)

  const [isViewed, setIsViewed] = useState<boolean>(false)

  const DELAY_TRANSITION_TIME = 6000

  const trackingData = useTracking()

  const promotionsTrackingData = useMemo(
    () =>
      homeBanners.map((slide, index) => ({
        promotion_id: trackingString(slide.promotion?.sys.id),
        promotion_name: trackingString(slide.promotion?.name as string),
        promotion_position:
          slide.page && slide.location ? trackingString(`${slide.page} ${slide.location} Banner ${index + 1}`) : '',
        creative: isMobile
          ? trackingString(slide.frontImageMobile?.title as string)
          : trackingString(slide.frontImageDesktop?.title as string),
      })),
    [homeBanners, isMobile]
  )

  // Auto slide transition
  useEffect(() => {
    const transition = setTimeout(() => {
      const newNavigationIndex = navigationIndex + 1
      if (newNavigationIndex >= homeBanners.length) setNavigationIndex(0)
      else {
        setNavigationIndex(newNavigationIndex)
      }
      setIsViewed(false)
    }, DELAY_TRANSITION_TIME)
    return () => {
      clearTimeout(transition)
    }
  }, [homeBanners, navigationIndex, setIsViewed])

  // Track promotion viewed
  useEffect(() => {
    if (typeof window === 'undefined' || isViewed || !isVisible) return
    setIsViewed(true)
    trackPromotionViewed({ ...promotionsTrackingData[navigationIndex], ...trackingData })
  }, [isVisible, isViewed, setIsViewed, promotionsTrackingData, trackingData, navigationIndex])

  function handleSetNavigationIndex(index: number) {
    setNavigationIndex(index)
    setIsViewed(false)
  }

  const styleOverrides = compose({
    container: styles['unionCarouselContainer'],
    slide: styles['unionCarouselSlide'],
    nextButton: styles['unionCarouselNextButton'],
    prevButton: styles['unionCarouselPrevButton'],
  })

  return (
    <div className={styles.carousel} ref={carouselRef}>
      <UnionCarousel
        visibleSlots={1}
        navigationIndex={navigationIndex}
        navigationRange={navigationRange}
        onNavigationRangeChange={setNavigationRange}
        onNavigationIndexChange={handleSetNavigationIndex}
        renderNextButton={NextButton}
        renderPrevButton={PrevButton}
        className={styles.carouselContainer}
        classes={styleOverrides}
      >
        {homeBanners.map((slide, index) => (
          <BannerSlide
            key={index}
            slidePosition={index}
            slide={slide}
            isMobile={isMobile}
            slideTrackingData={{ ...promotionsTrackingData[index], ...trackingData }}
          />
        ))}
      </UnionCarousel>
      <NavigationBullets
        itemsLength={homeBanners.length}
        navigationIndex={navigationIndex}
        setNavigationIndex={handleSetNavigationIndex}
      />
    </div>
  )
}

function buildImageData(slide: HomeBanner, isMobile: boolean) {
  return isMobile
    ? {
        src: mediaAPILoader(slide?.frontImageMobile?.url as string) || '',
        width: 768,
        height: 0,
        quality: 100,
        alt: slide.frontImageMobile?.title || '',
      }
    : {
        src: mediaAPILoader(slide.frontImageDesktop?.url as string) || '',
        width: 1410,
        height: 0,
        quality: 100,
        alt: slide.frontImageDesktop?.title || '',
      }
}

type BannerSlideProps = {
  slidePosition: number
  slide: HomeBanner
  slideTrackingData: TrackingProps & PromotionViewed
  className?: string
  isMobile?: boolean
}

export function BannerSlide({
  slidePosition,
  slide,
  className,
  isMobile = false,
  slideTrackingData,
}: BannerSlideProps) {
  const slideImageData = useMemo(() => buildImageData(slide, isMobile), [isMobile, slide])

  // Track promotion clicked on text link
  useEffect(() => {
    const anchor = document.getElementById(`banner-slide-text-link-${slidePosition}`) as HTMLAnchorElement

    if (!anchor) return

    const handleClick = async (e: MouseEvent) => {
      e?.stopImmediatePropagation()
      e?.preventDefault()

      await trackPromotionClicked(slideTrackingData, () => redirectTo(anchor.href as string))
    }

    anchor.addEventListener('click', handleClick)

    return () => anchor.removeEventListener('click', handleClick)
  }, [slideTrackingData, slidePosition])

  if (!slide) return null

  const lazyLoadPropVal = slidePosition == 0 ? 'eager' : 'lazy'

  async function handleBannerClick() {
    if (!slide.bannerLink) return

    await trackPromotionClicked(slideTrackingData, () => redirectTo(slide.bannerLink as string))
  }

  async function handleButtonClick() {
    if (!slide.buttonLink) return

    await trackPromotionClicked(slideTrackingData, () => redirectTo(slide.buttonLink as string))
  }

  return (
    <div
      key={slidePosition}
      className={cx(styles.slide, { [styles.pointer]: !!slide.bannerLink }, className)}
      onClick={handleBannerClick}
      onAuxClick={handleBannerClick}
    >
      <Image
        className={cx({ [styles.image]: true, [styles.mobile]: isMobile, [styles.desktop]: !isMobile })}
        unoptimized
        src={slideImageData.src}
        alt={slideImageData.alt}
        width={slideImageData.width}
        height={slideImageData.height}
        sizes="100vw"
        loading={lazyLoadPropVal}
        style={{ width: '100%', height: 'auto' }}
      />
      <div className={cx(styles.wrapperContainer, styles[slide.template?.toLowerCase() || 'text-image'])}>
        <div className={styles.columnContainer}>
          <div className={cx(styles.textContainer, styles[slide.justifyText?.toLowerCase() || 'left'])}>
            {renderCarouselHeroText(slide?.text, slidePosition)}
            <Button
              data-testid="hero-slide-primary-cta"
              className={styles.slideCta}
              color={'primary'}
              size={isTenant('ww') ? 'lg' : 'md'}
              onClick={handleButtonClick}
            >
              {slide.buttonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
