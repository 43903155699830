import cx from 'classnames'
import styles from './NavigationBullets.module.scss'
type NavigationBulletsProps = {
  itemsLength: number
  navigationIndex: number
  setNavigationIndex: (index: number) => void
} & React.HTMLAttributes<HTMLDivElement>

export function NavigationBullets({
  itemsLength,
  navigationIndex,
  setNavigationIndex,
  className,
  ...props
}: NavigationBulletsProps) {
  if (itemsLength <= 1) return null

  const isSecondItemActive = navigationIndex === 1

  return (
    <div className={cx(styles.navigationBullets, className)} {...props}>
      {Array.from({ length: itemsLength }).map((_, idx) => {
        const isSiblingActive = idx === 0 && isSecondItemActive
        return (
          <div
            key={idx}
            className={cx(styles.bullet, { [styles.activeSibling]: isSiblingActive })}
            data-state={navigationIndex === idx ? 'active' : 'inactive'}
            onClick={() => setNavigationIndex(idx)}
          />
        )
      })}
    </div>
  )
}
