import { ComponentType, PropsWithChildren } from 'react'
import { TopLevelContainer as UnionTopLevelContainer } from '@xo-union/tk-component-grid'
import { compose } from '@xo-union/react-css-modules'
import styles from './styles.module.css'
import { isTenant } from '@/utils/config'

const classOverrides = compose({
  'top-level-container': isTenant('ww') ? styles.topLevelContainerWW : {},
})

const topLevelContainerProps = isTenant('ww') ? { classes: classOverrides } : null

export const TopLevelContainer: ComponentType<PropsWithChildren<unknown>> = ({ children }) => {
  return <UnionTopLevelContainer {...topLevelContainerProps}>{children}</UnionTopLevelContainer>
}
