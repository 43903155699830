import { IconButton } from '@xo-union/tk-component-icons'
import styles from './CarouselHeroButtons.module.scss'

interface SlideButtonProps {
  className?: string
}

export const NextButton = (props: SlideButtonProps) => (
  <IconButton size="lg" name="caret_right" {...props} className={`${styles.slideButton} ${props.className}`} />
)

export const PrevButton = (props: SlideButtonProps) => (
  <IconButton
    size="lg"
    name="caret_left"
    {...props}
    className={`${styles.slideButton} 
        ${props.className}`}
  />
)
