import { Button as ButtonTK, Link as ButtonLinkTK } from '@xo-union/tk-component-buttons'
import { useClasses } from '@xo-union/react-css-modules'
import { isTenant } from '@/utils/config'

import buttonStylesWW from './stylesWW.module.scss'

const ButtonWW = (props: React.ComponentProps<typeof ButtonTK>) => {
  const classesWW = useClasses(buttonStylesWW)

  return <ButtonTK {...props} classes={classesWW} />
}

const PaperButton = isTenant('ww') ? ButtonWW : ButtonTK

const TKPaperButtonAsLink = ButtonLinkTK

export { PaperButton as Button, TKPaperButtonAsLink }
